<template>
	<div id="GoToReportBlock">
		{{ audioValidated() }}
		<v-card
			v-if="display !== 'end-normal-exercise' && display !== ''"
			class="div-report white--text d-flex align-center flex-column flex-md-row"
			:class="backgroundColor"
		>
			<div class="left-block">
				<v-img
					v-if="display === 'end-of-category'"
					src="@/assets/images/goToReport/end-category.png"
					class="image"
				></v-img>
				<v-img
					v-else-if="display === 'end-of-parcours'"
					src="@/assets/images/goToReport/end-parcours.png"
					class="image"
				></v-img>
				<v-img
					v-else-if="display === 'end-of-formation'"
					src="@/assets/images/goToReport/end-formation.png"
					class="image"
				></v-img>
				<h2 class="ml-2 mr-3">{{ content }}</h2>
			</div>
			<v-btn
				:to="reportUrl"
				medium
				class="button-report mr-0 mr-md-4 mt-2 mt-md-0 black--text"
				color="black"
				dark
				@click="$confetti.stop()"
			>
				Voir mon rapport</v-btn
			>
		</v-card>
		<div v-else-if="display === 'end-normal-exercise'">
			<v-alert v-if="totalScoreInPercent == 100" prominent type="success" color="#FFD15E">
				<strong> Fantastique, {{ totalScoreInPercent }}% de bonnes réponses, c'est un parfait ! </strong>
			</v-alert>
			<v-alert v-else prominent type="success" color="#2dc7ae">
				<strong
					>Félicitations, tu débloques la prochaine partie avec {{ totalScoreInPercent }} % de bonnes réponses</strong
				>
			</v-alert>
		</div>
	</div>
</template>

<script>
import { ParcoursItemStatus } from '@/constants/parcours.js';
import { CategoryStatus } from '@/constants/category';
import exerciseValidated from '@/assets/sound/exercise-validated.mp3';
import categoryFinished from '@/assets/sound/next-program-unlocked.mp3';
import parcoursFinished from '@/assets/sound/next-parcours-unlocked.mp3';
import formationFinished from '@/assets/sound/formation-finished.mp3';

export default {
	name: 'GoToReportBlock',
	props: {
		formationProgress: Object,
		categoryId: String,
		totalScoreInPercent: Number,
	},
	data() {
		return { display: '', notRedoExercise: false, timeoutId: null, storageKeySound: 'acceptSound' };
	},
	computed: {
		content() {
			if (this.display === 'end-of-category') return 'Félicitations, tu as terminé ton programme !';
			if (this.display === 'end-of-parcours') return 'Félicitations, tu viens de débloquer ton prochain parcours';
			if (this.display === 'end-of-formation') return 'Félicitations, tu viens de finir ta formation';
			else return '';
		},
		reportUrl() {
			if (this.display === 'end-of-category')
				return `/report/category/${this.formationProgress.formation._id}/${this.categoryId}?notRedo=${this.notRedoExercise}`;
			if (this.display === 'end-of-parcours') {
				const parcoursId = this.findParcoursIdInFormationConfig(this.categoryId);
				return `/report/parcours/${this.formationProgress.formation._id}/${parcoursId}?notRedo=${this.notRedoExercise}`;
			}
			if (this.display === 'end-of-formation') {
				const parcoursId = this.findParcoursIdInFormationConfig(this.categoryId);
				return `/report/end-formation/${this.formationProgress.formation._id}/${parcoursId}?notRedo=${this.notRedoExercise}`;
			} else return '';
		},
		backgroundColor() {
			if (this.display === 'end-of-category') return 'darkPurple';
			if (this.display === 'end-of-parcours') return 'violet';
			if (this.display === 'end-of-formation') return 'darkPurple';
			else return '';
		},
	},
	mounted() {
		this.notRedoExercise = this.formationProgress.formation.last_exercice === this.$route.params.idExercice;
		let thisCategory = null;
		let isThisLastCatOfParcours = false;

		this.formationProgress.config.parcours_list.forEach((parcoursItem) => {
			const foundCategory = parcoursItem.parcours_id.categories.find((category) => category._id === this.categoryId);
			if (foundCategory != null) {
				thisCategory = foundCategory;
				isThisLastCatOfParcours = this.isLastCategoryOfParcours(parcoursItem.parcours_id);
			}
		});
		if (thisCategory == null) return;

		const thisExercise = thisCategory.exercises.find((exercise) => exercise._id === this.$route.params.idExercice);
		if (thisExercise == null) return;

		const isThisLastExerciseOfCategory = thisExercise.position_list === thisCategory.exact_nb_exos;
		const isLastParcoursOfFormation = this.isLastParcoursOfFormation(this.formationProgress.config.parcours_list);

		if (isThisLastExerciseOfCategory && isThisLastCatOfParcours && isLastParcoursOfFormation)
			this.display = 'end-of-formation';
		else if (isThisLastExerciseOfCategory && isThisLastCatOfParcours) this.display = 'end-of-parcours';
		else if (isThisLastExerciseOfCategory) this.display = 'end-of-category';
		else this.display = 'end-normal-exercise';
	},
	beforeDestroy() {
		if (this.timeoutId != null) clearTimeout(this.timeoutId);
	},
	methods: {
		audioValidated() {
			const cookie = window.localStorage.getItem(this.storageKeySound);
			var exerciseValidatedAudio = new Audio(exerciseValidated);
			var categoryFinishedAudio = new Audio(categoryFinished);
			var parcoursFinishedAudio = new Audio(parcoursFinished);
			var formationFinishedAudio = new Audio(formationFinished);

			if (cookie == null) {
				if (this.display == 'end-of-formation') {
					formationFinishedAudio.play();
				} else if (this.display == 'end-of-parcours') {
					parcoursFinishedAudio.play();
				} else if (this.display == 'end-of-category') {
					categoryFinishedAudio.play();
				} else if (this.display == 'end-normal-exercise') {
					exerciseValidatedAudio.play();
				}
			}
		},
		isLastCategoryOfParcours(parcours) {
			// Because category can be done without order. Therefore, last category of parcour is if number of has been category + 1 == total category of parcours
			const totalPassedCategory = parcours.categories.filter((category) => category.status === CategoryStatus.COMPLETED)
				.length;
			return totalPassedCategory + 1 === parcours.categories.length;
		},
		isLastParcoursOfFormation(parcoursListItems) {
			const lockedParcoursItem = parcoursListItems.filter((item) => item.status === ParcoursItemStatus.locked).length;
			if (lockedParcoursItem === 0) return true;
			return false;
		},
		findParcoursIdInFormationConfig(categoryId) {
			let parcoursId = null;
			this.formationProgress.config.parcours_list.forEach((parcoursItem) => {
				const foundCategory = parcoursItem.parcours_id.categories.find((category) => category._id === categoryId);
				if (foundCategory != null) parcoursId = parcoursItem.parcours_id._id;
			});

			return parcoursId;
		},
	},
};
</script>

<style lang="scss" scoped>
#GoToReportBlock {
	z-index: 2;
}
.div-report {
	position: sticky;
	top: 60px;
	z-index: 10;
	padding-bottom: 10px;
	height: fit-content;
	min-height: 150px;
	justify-content: space-between;

	.button-report {
		background-color: white !important;
	}
	h2 {
		font-size: 20px;
	}

	.left-block {
		display: flex;
		align-items: center;

		.image {
			margin-left: 9px;
			max-width: 110px;
			max-height: 110px;
		}
	}
}

.darkPurple {
	background-color: #120136;
}
.violet {
	background-color: #7854f7;
}

@media screen and (max-width: 959px) {
	.div-report {
		.left-block {
			flex-direction: column;
			.image {
				margin: 10px 0;
			}
			h2 {
				margin-bottom: 20px;
			}
		}
	}
	.button-report {
		margin-bottom: 8px;
	}
}

@media screen and (max-width: 600px) {
	.div-report {
		.left-block {
			h2 {
				text-align: center;
				margin-bottom: 0px;
				font-size: 19px;
			}
		}
	}
}
</style>
